<template>
  <v-row class="ticket">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/support" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.number }}
          </router-link>
        </div>
        <div class="text-right">
          <v-btn v-if="data.can_reopen" @click="action('reopen')" class="white--text ml-5" width="184" height="44" color="secondary">
            Reopen
          </v-btn>
          <v-btn v-if="data.can_close" @click="action('close')" class="white--text ml-5" width="184" height="44" color="secondary">
            Close
          </v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15">
        <div class="px-10 py-2 d-flex flex-wrap justify-space-between align-center">
          <v-list color="white" class="pa-0">
            <v-list-item class="pl-0">
              <v-list-item-avatar size="48" class="my-0">
                <v-img v-if="data.staff" :src="data.staff.photo"></v-img>
                <div v-else>
                  <v-icon>mdi-robber mdi-48px</v-icon>
                </div>
              </v-list-item-avatar>
              <div class="d-flex flex-column">
                <v-list-item-title v-if="data.staff" class="user-role clip font-weight-medium">
                  {{ data.staff.first_name }} {{ data.staff.last_name }}
                </v-list-item-title>
                <v-list-item-title v-else class="user-role clip font-weight-medium">Wait...</v-list-item-title>
                <v-list-item-title v-if="data.title" class="text-caption user-role clip font-weight-medium gray--text">
                  {{ data.title }}
                </v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
          <div class="d-flex align-center">
            <div class="f18 font-weight-bold">{{ data.topic.title }}</div>
            <v-divider class="mx-4" vertical></v-divider>
            <div class="status font-weight-medium f18" :class="getColorClass(data.status.name)">{{ data.status.title }}</div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="overflow-y-auto px-sm-10 py-sm-5 pa-2" style="height: calc(100vh - 330px)" id="window">
          <div v-for="item in msgList" :key="item.id">
            <div v-if="item.type == 'system'" class="text-center text-caption opasity--text font-weight-medium">{{ item.text }}</div>
            <div style="width: max-content" v-else-if="item.media" class="mt-3" :class="!item.staff ? 'ml-auto' : ''">
              <img
                class="d-block msg-img rounded-t-xl"
                :class="!item.staff ? 'rounded-bl-xl' : 'rounded-br-xl'"
                :src="item.media.url"
                alt="image"
              />
            </div>
            <div
              v-else
              class="mt-3 rounded-b-xl py-2 px-4 f18"
              style="width: max-content; max-width: 55%"
              :class="!item.staff ? 'rounded-tl-xl accent ml-auto' : 'input white--text rounded-tr-xl'"
            >
              {{ item.text }}
            </div>
            <div
              v-if="item.type != 'system'"
              class="text-caption opasity--text"
              :class="!item.staff ? 'ml-auto' : ''"
              style="width: max-content"
            >
              {{ new Date(item.created_at).toLocaleString() }}
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="accent px-sm-10 px-2 py-1 d-flex flex-wrap align-center">
          <v-btn @click="changeFile" elevation="0" color="secondary" width="40" height="40"><v-icon>mdi-plus</v-icon></v-btn>
          <v-text-field
            v-model="msg"
            placeholder="Type a message..."
            :error-messages="msgErrors"
            class="mx-sm-10 mx-3 mt-4"
            clearable
            @click:clear="clearMsg"
            dense
            height="38"
            required
            color="input"
            v-if="!file"
          >
          </v-text-field>
          <div v-else style="height: 72px" class="d-flex align-center mx-sm-10 mx-3">
            <v-chip close @click:close="(file = ''), (msg = '')" color="accent" text-color="opasity">{{ media }}</v-chip>
          </div>
          <v-spacer v-if="file"></v-spacer>
          <v-btn @click="send" elevation="0" :color="msg ? 'input' : 'secondary'" width="40" height="40" dark>
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    msg: { required },
  },
  data() {
    return {
      load: true,
      msg: '',
      file: '',
      media: '',
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getTicketMsg');
    this.$store.dispatch('timerTicket');
  },
  methods: {
    getColorClass(block) {
      if (block == 'submitted') {
        return 'cyan';
      } else if (block == 'processing') {
        return 'success';
      } else if (block == 'reopened') {
        return 'input';
      } else if (block == 'closed') {
        return 'error';
      }
    },
    async getData() {
      await this.$store.dispatch('getTicket').finally(() => {
        this.load = false;
      });
    },
    async action(type) {
      const data = new Object();
      data.action = type;
      await this.$store.dispatch('actionTicket', data);
    },
    async send() {
      this.$v.msg.$touch();
      if (!this.$v.msg.$invalid) {
        const formData = new FormData();
        if (this.file) {
          formData.append('picture', this.file);
          formData.append('type', 'media');
        } else {
          formData.append('text', this.msg);
          formData.append('type', 'text');
        }
        await this.$store.dispatch('sendTicketMsg', formData).then(() => {
          this.clearMsg();
        });
      }
    },
    clearMsg() {
      this.msg = '';
      this.$v.msg.$reset();
    },
    changeFile() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.msg = self.file.name;
            self.media = self.file.name;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    bottomChat() {
      const block = document.getElementById('window');
      block.scrollTo(0, block.scrollHeight || 0);
    },
  },
  watch: {
    msgList() {
      setTimeout(() => {
        this.bottomChat();
      }, 100);
    },
  },
  computed: {
    data() {
      return this.$store.getters.support;
    },
    msgList() {
      return this.$store.getters.supportMsg;
    },
    msgErrors() {
      const errors = [];
      if (!this.$v.msg.$dirty) {
        return errors;
      }
      !this.$v.msg.required && errors.push('Please enter your message');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTicket', { topic: {}, staff: {}, status: {} });
    this.$store.dispatch('setTicketMsg', []);
    this.$store.dispatch('closeWebSocketTicket');
  },
};
</script>

<style lang="scss">
.ticket {
  .status {
    color: white;
    line-height: 34px;
    padding: 0 16px;
    width: max-content;
    border-radius: 24px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px !important;
  }
  .msg-img {
    height: 200px;
  }
  @media screen and (max-width: 599px) {
    .msg-img {
      height: 150px;
    }
  }
}
</style>
